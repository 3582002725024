<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Paso 3 de 5
            </template>
            <template v-slot:subtitle>
                Datos Personales
            </template>
            <template v-slot:content>                
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-2">
						<label class="p-mb-2">Nacionalidad</label>
						<Dropdown v-model="estudiante.nac" :options="nac" optionLabel="nombre" placeholder="?..."></Dropdown>
                        <small v-show="validationErrors.nac && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-2">
						<label for="name">Cédula</label>
						<InputNumber v-model="estudiante.cedula" integeronly required="true" min="0" disabled/>
                        <small v-show="validationErrors.cedula && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-4">
						<label for="name">Nombres como aparece en la Cédula</label>
						<InputText v-model="estudiante.nombre" required="true"/>
                        <small v-show="validationErrors.nombre && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-4">
						<label for="name">Apellidos  como aparece en la Cédula</label>
						<InputText v-model="estudiante.apellido" required="true"/>
                        <small v-show="validationErrors.apellido && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-2">
						<h6 style="margin-top: 0" v-if="estudiante.sexo">Masculino</h6>
						<h6 style="margin-top: 0" v-else>Femenino</h6>
						<InputSwitch v-model="estudiante.sexo" />
					</div>
                    <div class="p-field p-col-12 p-md-4">
						<label for="name">Correo</label>
						<InputText v-model="estudiante.correo" required="true"/>
                        <small v-show="validationErrors.correo && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-4">
						<label for="name">Confirmar Correo</label>
						<InputText v-model="conf_correo" required="true"/>
                        <small v-show="validationErrors.confcorreo && submitted" style="color: red;">No concuerda, verifique</small>
					</div>
                    <div class="p-field p-col-12 p-md-2">
						<label>Fec. Nacimiento</label>
						<InputText v-model="estudiante.fecha_nac" type="date"/>
                        <small v-show="validationErrors.fecha_nac && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-4">
						<label class="p-mb-2">País Nac.</label>
						<Dropdown v-model="estudiante.pais_nac" :options="paisnac" optionLabel="nombre" placeholder="Selecciona..."  @change="Validacion1()"></Dropdown>
                        <small v-show="validationErrors.pais_nac && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-4">
						<label class="p-mb-2">Estado Nac.</label>
						<Dropdown v-model="estudiante.estado_nac" :options="estadonac" optionLabel="nombre" placeholder="Selecciona..." v-if="estudiante.pais_nac" @change="Validacion2()"></Dropdown>
                        <small v-show="validationErrors.estado_nac && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-4">
						<label class="p-mb-2">Lugar Nac.</label>
						<Dropdown v-model="estudiante.lugar_nac" :options="lugarnac" optionLabel="nombre" placeholder="Selecciona..." v-if="estudiante.estado_nac"></Dropdown>
                        <small v-show="validationErrors.lugar_nac && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-4">
						<label class="p-mb-2">Est.Civil</label>
						<Dropdown v-model="estudiante.est_civil" :options="estcivil" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
                        <small v-show="validationErrors.est_civil && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-4">
						<label class="p-mb-2">Etnia</label>
						<Dropdown v-model="estudiante.etnia" :options="etnias" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
                        <small v-show="validationErrors.etnia && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-4">
						<label class="p-mb-2">Discapacidad</label>
						<Dropdown v-model="estudiante.discapacidad" :options="discapacidades" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
                        <small v-show="validationErrors.discapacidad && submitted" style="color: red;">Campo obligatorio</small>
					</div>
                    <div class="p-field p-col-12 p-md-3">
                        <label>Telf Habitación</label>
                        <InputMask v-model="estudiante.tlf_local" mask="(9999) 999-9999" />
                    </div>                      
                    <div class="p-field p-col-12 p-md-3">
                        <label>Telf Celular</label>
                        <InputMask v-model="estudiante.tlf_celular" mask="(9999) 999-9999" />
                        <small v-show="validationErrors.tlf_celular && submitted" style="color: red;">Campo obligatorio</small>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
						<h6 style="margin-top: 0" v-if="estudiante.whasa">Con WhatsApp</h6>
						<h6 style="margin-top: 0" v-else>Sin WhatsApp</h6>
						<InputSwitch v-model="estudiante.whasa" />
					</div>
                    <div class="p-field p-col-12 p-md-3">
                        <label>Telf Adicional</label>
                        <InputMask v-model="estudiante.tlf_otro" mask="(9999) 999-9999" />
                    </div>                      
                </div>
                <div class="p-fluid p-formgrid p-grid">                   
                    <div class="p-field p-col-12 p-md-3">
                        <FileUpload chooseLabel="Subir Copia de Cédula JPG" uploadLabel="Cargar" mode="basic" :name="token" :url="url+'doc.php'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader" :auto="true"/>
                        <small v-show="validationErrors.doc_cedula && submitted" style="color: red;">Debe cargar la Imagen</small>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
						<img style="width: 100px;" :src="url+'doc/'+estudiante.doc_cedula+'.jpg'" v-if="estudiante.doc_cedula"/>
                    </div>
                </div>          
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button label="Siguiente" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/API";
import Token from "uuid-token-generator";
const Consulta = new API('Preinscripcion','Procesar');

export default {
    data () {
        return {
            submitted: false,
            validationErrors: {},
			paisnac: [],
			estadonac: [],
			lugarnac: [],
			estcivil: [],
			etnias: [],
			discapacidades: [],
			nac: [
				{code: 'V', nombre: 'Venezolano(a)'},
				{code: 'E', nombre: 'Extranjero(a)'}
			],
            estudiante: {
                nac: null,
                cedula: null,
                correo: null,
                apellido: null,
                nombre: null,
                fecha_nac: null,
                tlf_local: null,
                tlf_celular: null,
                tlf_otro: null,
                whasa: false,
                sexo: true,
                pais_nac: {code: 296, nombre: 'Venezuela'},
                estado_nac: null,
                lugar_nac: null,
                est_civil: null,
                etnia: null,
                discapacidad: null,
                doc_cedula: null,
            },
            token: null,
            conf_cedula: null,     
            conf_correo: null,     
        }
    },
	created() {
        this.url = process.env.VUE_APP_API;
        const tokgen = new Token();
        this.token = tokgen.generate();
		this.Mostrar();
        this.estudiante.cedula = this.$store.state.matricula;
	},
    methods: {
		Mostrar() {
			Consulta.Procesar('Parametros',{
			}).then(response => {
				//this.$store.state.error =response;
                this.paisnac = response.result.paisnac;
                this.estadonac = response.result.estadonac;
                this.estadonac = this.estadonac.filter(val => val.code != 25);                    
                this.estcivil = response.result.estcivil;
                this.etnias = response.result.etnias;
                this.discapacidades = response.result.discapacidades;
				this.$store.commit('Loading');
			});
		},
		Validacion1() {
            this.estudiante.estado_nac = null;
			Consulta.Procesar('Parametros',{
			}).then(response => {
                this.estadonac = response.result.estadonac;
                if (this.estudiante.pais_nac.code!=296){
                    this.estadonac = this.estadonac.filter(val => val.code == 25);
                } else {
                    this.estadonac = this.estadonac.filter(val => val.code != 25);                    
                }
				this.$store.commit('Loading');
			});
		},
		Validacion2() {
            this.estudiante.lugar_nac = null;
			Consulta.Procesar('Parametros',{
			}).then(response => {
                //this.$store.state.error = response.result.lugarnac;
                this.lugarnac = response.result.lugarnac;
                this.lugarnac = this.lugarnac.filter(val => val.estado == this.estudiante.estado_nac.code);
				this.$store.commit('Loading');
			});
		},
		myUploader() {
			Consulta.Procesar('ImgDoc',{
				img: this.token,
			}).then(response => {
                if(response.result){
                    this.estudiante.doc_cedula = this.token;
                }
                const tokgen = new Token();
                this.token = tokgen.generate();
				this.$store.commit('Loading');
			});
        },
        nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
                this.$emit('next-page', {formData: {estudiante: this.estudiante}, pageIndex: 2});
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 2});
        },
        validateForm() {
            if (this.estudiante.nac==null)
                this.validationErrors['nac'] = true;
            else
                delete this.validationErrors['nac'];
            if (this.estudiante.cedula==null)
                this.validationErrors['cedula'] = true;
            else
                delete this.validationErrors['cedula'];
            if (this.estudiante.correo==null)
                this.validationErrors['correo'] = true;
            else
                delete this.validationErrors['correo'];
            if (this.estudiante.correo!=this.conf_correo)
                this.validationErrors['confcorreo'] = true;
            else
                delete this.validationErrors['confcorreo'];
            if (this.estudiante.apellido==null)
                this.validationErrors['apellido'] = true;
            else
                delete this.validationErrors['apellido'];
            if (this.estudiante.nombre==null)
                this.validationErrors['nombre'] = true;
            else
                delete this.validationErrors['nombre'];
            if (this.estudiante.fecha_nac==null)
                this.validationErrors['fecha_nac'] = true;
            else
                delete this.validationErrors['fecha_nac'];
            if (this.estudiante.pais_nac==null)
                this.validationErrors['pais_nac'] = true;
            else
                delete this.validationErrors['pais_nac'];
            if (this.estudiante.estado_nac==null)
                this.validationErrors['estado_nac'] = true;
            else
                delete this.validationErrors['estado_nac'];
            if (this.estudiante.lugar_nac==null)
                this.validationErrors['lugar_nac'] = true;
            else
                delete this.validationErrors['lugar_nac'];
            if (this.estudiante.est_civil==null)
                this.validationErrors['est_civil'] = true;
            else
                delete this.validationErrors['est_civil'];
            if (this.estudiante.etnia==null)
                this.validationErrors['etnia'] = true;
            else
                delete this.validationErrors['etnia'];
            if (this.estudiante.discapacidad==null)
                this.validationErrors['discapacidad'] = true;
            else
                delete this.validationErrors['discapacidad'];
            if (!this.estudiante.tlf_celular)
                this.validationErrors['tlf_celular'] = true;
            else
                delete this.validationErrors['tlf_celular'];
            if (this.estudiante.doc_cedula==null)
                this.validationErrors['doc_cedula'] = true;
            else
                delete this.validationErrors['doc_cedula'];

            return !Object.keys(this.validationErrors).length;
        }
    }
}
</script>